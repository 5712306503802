@import '~Styles/variables';

.ImageUploader {

	:global(.dropzone) {
		width: 100%;
		min-height: 15rem;
		background-color: $gray-lightest;
    border: 3px dashed darken($gray-lightest, 5%);
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    color: $gray-light;
    cursor: pointer;
    margin-bottom: 2rem;
	}
}

.files {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.bulk-actions {
  margin-top: 2rem;

  > :global(.btn) {
    & + :global(.btn) {
      margin-left: 1rem;
    }
  }
}

.options {
  
}

@charset "UTF-8";
.form {
  padding-bottom: 4.5rem; }
  .form > :global(.form-group) > :global(.control-wrapper) input:not(:global(.slug-field)),
  .form > :global(.form-group) > :global(.control-wrapper) textarea {
    font-family: 'Georgia', serif; }

.footer {
  position: fixed;
  bottom: 0;
  left: 15rem;
  right: 0;
  background-color: gray(50%);
  padding: 1rem; }

.node-actions {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 2.5rem; }
  .node-actions :global .btn {
    color: #464a4c;
    background-color: #f7f7f9;
    border-color: #dadae3; }
    .node-actions :global .btn:hover {
      color: #464a4c;
      background-color: #dadae3;
      border-color: #b7b7c9; }
    .node-actions :global .btn:focus, .node-actions :global .btn.focus {
      box-shadow: 0 0 0 2px rgba(218, 218, 227, 0.5); }
    .node-actions :global .btn.disabled, .node-actions :global .btn:disabled {
      background-color: #f7f7f9;
      border-color: #dadae3; }
    .node-actions :global .btn:active, .node-actions :global .btn.active,
    .show > .node-actions :global .btn.dropdown-toggle {
      color: #464a4c;
      background-color: #dadae3;
      background-image: none;
      border-color: #b7b7c9; }

.process-images-btn {
  font-size: .75em;
  padding: .25em .5em;
  margin-left: .5em;
  margin-right: .5em; }
  .process-images-btn:global(.is-success) {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d0e9c6; }
    .process-images-btn:global(.is-success)::after {
      content: '✓';
      display: inline-block;
      margin-left: .5em;
      font-weight: 800; }

.process-image-error {
  color: #a94442;
  background-color: #f2dede;
  padding: .5em;
  margin-bottom: .5em;
  border-radius: 3px; }

.left {
  display: flex;
  flex-flow: row;
  align-items: center; }
  .left > a {
    display: block;
    color: gray(75%);
    font-size: .8em;
    padding: .5em .75em; }

.action-buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  min-width: 14rem;
  height: 100%; }
  .action-buttons :global .dropdown {
    position: static;
    height: 100%; }
  .action-buttons :global .dropdown-toggle {
    height: 100%;
    margin-left: -1px;
    font-size: 0.8em; }
  .action-buttons :global .btn {
    text-align: left; }

.primary-action {
  flex: 1;
  height: 100%; }

.extra-actions-menu {
  top: inherit !important;
  left: 1rem;
  bottom: 100%;
  min-width: 14rem;
  background-color: gray(50%); }
  .extra-actions-menu li :global .btn {
    display: block;
    width: 100%; }
    .extra-actions-menu li :global .btn + :global(.btn) {
      margin-bottom: -1px; }

.revisions {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center; }
  .revisions :global(.dropdown) {
    position: static;
    height: 100%; }
    .revisions :global(.dropdown) :global(.dropdown-toggle) {
      height: 100%;
      font-size: 0.8em; }

.current-revision {
  padding: .5em .75em;
  color: gray(75%);
  font-size: .8em; }

.revisions-list {
  background-color: #fff;
  top: inherit !important;
  left: inherit !important;
  bottom: 100%;
  right: 0;
  margin-bottom: 1rem !important; }

.revision {
  padding: .5rem 1rem;
  border: 1px solid gray(95%);
  min-width: 15em;
  display: block;
  margin-top: -1px;
  text-decoration: none;
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: .9em;
  white-space: nowrap; }
  .revision img {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -1rem;
    margin-right: .5rem;
    height: 2.5em; }
  .revision:global(.active) {
    background-color: gray(80%); }

.tag {
  display: inline-block;
  border: 1px solid gray(60%);
  border-radius: .25em;
  padding: .25em .5em;
  font-size: .8em;
  margin-left: .5rem; }

.delete-modal :global(.content) {
  height: auto; }

.delete-modal .delete-body {
  padding: 1rem; }

.delete-modal .delete-footer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end; }
  .delete-modal .delete-footer :global(.btn) {
    margin-right: 1rem; }
    .delete-modal .delete-footer :global(.btn):last-child {
      margin-right: 0; }

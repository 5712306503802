.row {
	display: flex;
	flex-flow: row wrap;
  margin: 1em 0;
}

.label {
	flex: 1 0 0;
	min-width: 6rem;
	font-weight: bold;
	color: gray(50%);
	font-size: .7em;
	text-transform: uppercase;
	margin: .25em 0 .5em;
}

.value {
	flex: 3 1 0;
	margin: 0;
}

.map-wrapper {
	height: 10rem;
	width: 100%;
	min-width: 15rem;
}

.marker {
	color: #fff;
	font-size: 2rem;
	position: absolute;
	top: 0;
	left: -50%;
	line-height: .5;
	transform: translateX(-50%) translateY(-100%);
	text-shadow: 1px 3px 3px #000000cc;
}

.filters {
	text-align: center;
}

.show-button {
	font-size: .8em;
	border: none;
	padding: .2em;
}

.date-range {
  padding: 1rem 0;
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: .8em;
  color: gray(60%);
}

.fields {
  display: flex;
  flex-flow: row;

  :global(.form-group) {
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      padding-right: 1rem;
    }

    &:last-child {
      padding-left: 1rem;
    }

    :global(.react-datepicker-wrapper) {
      width: 100%;
    }
  }
}

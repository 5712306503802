@import '~Styles/variables';
@import '~Styles/mixins';

.app {
  display: flex;
  flex-flow: row;
}

.main-wrapper {
	flex: 1 100%;
	padding: 1rem $main-padding-x 3rem;
	background-color: gray(98%);
	border-color: gray(92%);
	border-style: solid;
	border-width: 0 0 1px 1px;
}

.main {
}

* {
  box-sizing: border-box;
}

body {
  background-color: $body-bg;
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 400;
  color: $body-color;
}

a,
:global(.link) {
  color: $link-color;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    color: darken($link-color, 15%);
  }

  &:active,
  &.active {
    color: darken($link-color, 25%);
  }
}

$btn-variants: (
  primary: ($white, $color-primary, 0),
  secondary: ($gray, $white, 20%),
  info: ($white, $color-info, 0),
  success: ($white, $color-success, 0),
  warning: ($white, $color-warning, 0),
  danger: ($white, $color-danger, 0),
);

:global(.btn) {
  $btn-focus-box-shadow: 0 0 0 2px rgba($color-primary, .25);
  $btn-active-box-shadow: inset 0 3px 5px rgba($black, .125);

  font-family: $font-family;
  display: inline-block;
  background-color: transparent;
  padding: .75em 1em;
  font-size: .9em;
  color: gray(40%);
  cursor: pointer;
  transition: background-color 300ms ease-in, border-color 300ms ease-in, color 300ms ease-in;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;

  @include button-size(.5rem, 1rem, 1rem, 1.25, 0);
  $atts: map-get($btn-variants, secondary);
  @include button-variant(nth($atts, 1), nth($atts, 2), darken(nth($atts, 2), nth($atts, 3)));

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: .65;
    box-shadow: none;
  }

  &:active,
  &.active {
    background-image: none;
    box-shadow: $btn-focus-box-shadow, $btn-active-box-shadow;
  }
}

@each $name, $atts in $btn-variants {
  :global(.btn-#{$name}) {
    @include button-variant(nth($atts, 1), nth($atts, 2), darken(nth($atts, 2), nth($atts, 3)));
  }
};

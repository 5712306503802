@import '~Styles/variables';

.structured-field {
	background-color: #fff;
	padding: .5rem 1rem;
}

.add-block {
	:global(.btn) {
    & + :global(.btn) {
      margin-left: -1px;
    }
  }
}

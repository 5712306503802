$body-bg: gray(50%);
$font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
$body-color: gray(30%);

$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green:  #5cb85c;
$blue:   #0275d8;
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;

// Create grayscale
$gray-dark:                 #292b2c;
$gray:                      #464a4c;
$gray-light:                #636c72;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;

// Reassign color vars to semantic color scheme
$color-primary:             $blue;
$color-success:             $green;
$color-info:                $teal;
$color-warning:             $orange;
$color-danger:              $red;
$color-inverse:             $gray-dark;

$link-color:                $color-primary;

$state-success-text:             #3c763d;
$state-success-bg:               #dff0d8;
$state-success-border-color:     darken($state-success-bg, 5%);

$state-info-text:                #31708f;
$state-info-bg:                  #d9edf7;
$state-info-border-color:        darken($state-info-bg, 7%);

$state-warning-text:             #8a6d3b;
$state-warning-bg:               #fcf8e3;
$mark-bg:                        $state-warning-bg;
$state-warning-border-color:     darken($state-warning-bg, 5%);

$state-danger-text:              #a94442;
$state-danger-bg:                #f2dede;
$state-danger-border-color:      darken($state-danger-bg, 5%);

$state-primary-bg:               lighten($color-primary, 52%);
$state-primary-text:             darken($state-primary-bg, 40%);
$state-primary-border-color:     darken($state-primary-bg, 5%);

$btn-border-width: 1px;

$sidebar-width: 15rem;
$main-padding-x: 2rem;

$modal-width-large: 80%;

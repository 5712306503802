$image-size: 5.5rem;

.node-list {

}

.nodes {
	list-style-type: none;
	padding: 0;
  font-size: .8rem;

	.header,
  .node > a {
		display: flex;
		flex-flow: row;
    align-items: center;
	}

  .node {
    border-bottom: 1px solid gray(95%);

    > a {
      text-decoration: none;
      color: gray(60%);
      transition: background-color 300ms ease;

      &:hover {
        background-color: gray(95%);
      }
    }
  }

  &:global(.has-featured-image) {
    .node {
      > a {
        height: $image-size;
      }
    }
  }
}

.page-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 4rem;
}

.column {
  padding: 1.75rem 1rem;
  width: 30%;
}

.header {
	text-transform: uppercase;
	border-bottom: 2px solid gray(80%);

  .column {
    color: gray(80%) !important;
    font-weight: bold !important;
    font-size: .9em !important;
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

.title {
	width: 40%;
  color: gray(40%);
  font-weight: 500;
  font-size: 1.25em;

	a {
		text-decoration: none;
	}
}

.featured-image {
  width: $image-size;
  height: 100%;
  padding: .5rem;
  padding-left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@import '~Styles/variables';

$selected-image-width: 20rem;

.ImageChooser {
	display: flex;
	flex-flow: column;
	height: 100%;
	text-align: left;
}

.header {
	flex: 0;
  font-size: .8rem;

  :global(.link) {
    opacity: .5;

    &:first-child {
      padding-left: 1rem;
    }

    &:global(.active) {
      opacity: 1;
    }
  }
}

.contentWrapper {
	flex: 1 1 auto;
	position: relative;	
}

.content {
	border-top: 1px solid gray(95%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.library {
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 100%;

  :global(.image-browser-grid) {
    :global(.image) {
      $min-width: 8rem;
      $max-columns: 8;
      @for $i from 2 through $max-columns {
        @media (min-width: ($selected-image-width + $i * $min-width) / ($modal-width-large / 100%)) {
          width: percentage(1/$i);
        }
      }
    }
  }
}

.browser {
	flex: 1 1 100%;
}

.selectedImage {
	flex: 0 0 $selected-image-width;
	width: $selected-image-width;
	border-left: 1px solid gray(95%);
	padding: 2%;
	overflow-y: auto;
}

.imagePreview {
	& img {
		width: 100%;
		height: auto;
	}
}

.footer {
	border-top: 1px solid gray(95%);
	padding: 2%;
}

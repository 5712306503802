@import '~Styles/variables';

.grid {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.item {
  width: 100%;

  $min-width: 9.375rem;
  $max-columns: 8;
  @for $i from 2 through $max-columns {
    @media (min-width: $sidebar-width + $main-padding-x * 2 + $i * $min-width) {
      width: percentage(1/$i);
    }
  }

  & > div {
    height: 0;
    padding-bottom: 100%;
    position: relative;

    & .action {
      background-color: gray(95%);
      border: 1px solid gray(90%);
      top: 0;
      left: 0;
      position: absolute;
      display: block;
      padding: 0;
      display: block;
      text-align: left;
      height: 100%;
      width: 100%;
      padding: 10%;
    }

    & > button {
      cursor: pointer;

      &:active,
      &:focus {
        outline: 0;
      }
    }
  }

  & img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.selected {
  & > div {
    & .action {
      background-color: gray(80%);
    }
  }
}

@import '~Styles/variables';

.button {
    background-color: transparent;
    border: 0;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: bold;
    color: $body-color;
    opacity: .6;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

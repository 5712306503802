.users {
	
}

.users-list {
	list-style-type: none;
	padding: 0;
}

.user {

}

.column {

}

.name {
	display: flex;
	flex-flow: row;
	align-items: center;

	> a {
		display: block;
		margin-left: .5rem;
		text-decoration: none;
	}
}

.gravatar {
	width: 3rem;
	height: 3rem;

	img {
		width: 100%;
		height: auto;
	}
}

@import '~Styles/variables';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1050;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.small {
	& .content {
		max-height: 30rem;
		max-width: 30rem;
	}
}

.large {
	& .content {
		
	}
}

.content {
	width: $modal-width-large;
	background-color: #fff;
	margin: 0 auto;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
	height: 100%;
	display: flex;
	flex-flow: column;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	max-height: 96vh;
	transform: translateY(-50%);
}

.backdrop {
	background-color: rgba(0, 0, 0, .3);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1040;
}

.header {

}

.default-header {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	padding: 1rem;
}

.header__title {
	margin: 0;
	padding: 0;
}

.body {
	flex: 1 100%;
	position: relative;
}

.footer {
	padding: 1rem;
}

@import '~Styles/mixins';

.root {
  @include clearfix;

	> a,
	> button {
		display: block;
		float: left;
		padding: .5em 1.5em;
		background: gray(95%);
		border: 1px solid gray(80%);
    cursor: pointer;

		&:global(.active) {
			background: transparent;
			border-bottom: none;
		}

    &:focus,
    &:active {
      outline: 0;
    }
	}
}

.dropdown {
	position: relative;
}

.toggle {
	&:global(.btn) {
		padding: .125rem .375rem;
	}
}

.menu {
	position: absolute;
	top: 100%;
	left: 0;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@import '~Styles/variables';
@import '~Styles/mixins';

$block-border-color: $gray-lighter;

.block {
	text-align: center;
	position: relative;
	padding: 1rem 0;

	:global(.rich-text-editor) {
		margin-top: 0;
    border-color: $block-border-color;
	}
}

.add-block,
.block-actions {
	transition: all 100ms ease-in;

  :global(.btn) {
    border: $btn-border-width solid transparent;
    opacity: 1;
    white-space: nowrap;

    @include button-size(.35rem, .5rem, .75rem, 1, 0);
    @include button-variant($gray-light, $white, $block-border-color);

    &:global(.close) {
      font-size: 1rem;
      padding-top: .2em;
      line-height: .9;
    }
  }
}

.add-block {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 50%;
	transform: translateX(-50%) translatey(-50%);
	z-index: 1020;

	.block:hover & {
		opacity: 1;
		visibility: visible;
	}
}

.block-actions {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	right: 0;
	transform: translateY(-50%);
	z-index: 1010;

  :global(.btn) {
    & + :global(.btn) {
      margin-left: -$btn-border-width;
    }
  }

	.block:hover & {
		opacity: 1;
		visibility: visible;
	}
}

.block {
  &:global(.hide-block-controls) {
    .add-block,
    .block-actions {
      display: none;
    }
  }
}

.image {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid $block-border-color;
	padding: 1rem;
}

.selected-images {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	max-width: 50%;

	img {
		max-height: 10rem;
		width: auto;
		margin-top: 1px;

		& + img {
			margin-left: 1px;
		}
	}

	&[data-columns='2'] {
		width: 20.5rem
	}

	&[data-columns='3'] {
		width: 30.5rem
	}

	&[data-columns='4'] {
		width: 40.5rem
	}

	&[data-columns='5'] {
		width: 50.5rem
	}

	&[data-columns='6'] {
		width: 60.5rem
	}
}

.image-controls {
	text-align: right;
}

.rich-text {

}

.html {
  border: 1px solid $block-border-color;
}

.embed {
  border: 1px solid $block-border-color;
  padding: 0 1rem 1rem;

  :global(.control-label) {
    text-align: left;
    font-weight: 400;
  }

  .embed-loading {
    font-style: italic;
    color: gray(70%);
    font-size: .8em;
  }

  .embed-content {
    display: flex;
    flex-flow: row;
  }

  .embed-details {
    > ul {
      @include list-unstyled;
      text-align: left;
      margin: 0;

      > li {
        margin: 1.5em 0;
        color: gray(30%);

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .embed-detail-label {
        font-weight: 600;
        font-size: .8em;
        color: gray(60%);
        margin-bottom: .5em;
      }
    }
  }

  .embed-preview {
    margin-right: 1.5rem;
  }
}

.messages {

}

.success {
  color: darkgreen;
}

.error {
  color: red;
}

.error-list {
  list-style-type: none;
  margin: 0;
  padding: 0;  
}

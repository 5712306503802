@import '~Styles/variables';


.file {
  $bg: $gray-lighter;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem .75rem;
  background-color: $bg;
  color: darken($bg, 50%);
  margin-top: 1px;
  border: 1px solid darken($bg, 5%);
}

.file__uploading {
  background-color: $state-primary-bg;
  color: $state-primary-text;
  border-color: $state-primary-border-color;
}

.file__error {
  background-color: $state-danger-bg;
  color: $state-danger-text;
  border-color: $state-danger-border-color;
}

.file__duplicate {
  background-color: $state-warning-bg;
  color: $state-warning-text;
  border-color: $state-warning-border-color;
}

.file__success {
  background-color: $state-success-bg;
  color: $state-success-text;
  border-color: $state-success-border-color;
}

.filePreview {
  height: 5rem;
  width: auto;
}

.fileName {
  padding: .5rem 1rem;
}

.file-details {
  display: flex;
  flex-flow: row;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 1rem
}

.upload-status {
  flex: 0 1 35%;
  text-align: right;
  margin-left: 1rem;
}

.browser {
	max-height: 100%;

	&:global(.scroll-container) {
		height: 100%;
		display: flex;
		flex-flow: column;
	}
}

.filters {
}

:global(.modal) {
	.filters {
		padding: 0 1rem 1rem;
	}
}

.search-ordering {
	display: flex;
	flex-flow: row;
  justify-content: space-between;
  align-items: center;

	:global(.form-group) {
		width: 80%;
	}
}

.images {
	overflow-y: auto;
	height: 100%;
}
